import React, { Component } from "react";
import "./Style.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { FaBell } from 'react-icons/fa';

import "react-multi-carousel/lib/styles.css";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";
import Categories from "../../categories/Categories";
import Cards from "../../Cards/PopularInstructors";

export default class StudentLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      usersType: sessionStorage.getItem("userType"),
      isRegistered: sessionStorage.getItem("isInstructor"),
      instructorRedirect: false,
      instructorList: [],
      instructor: {},
      finalInstructors: [],
      final: [],
      examsList: [],
      loading: true,
      verified: false,
      image: AppConstants.IMG_URL,
    };
  }

  componentDidMount() {
    this.checkUser();
    if (this.state.userDetails) {
      this.setState({ loginStatus: true });
    } else {
      this.setState({ loginStatus: false });
    }
    let user = this.state.userData;
    this.setState({ verified: user.is_email_verified });
    this.getExams();
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          console.log("Please login sdsdfirst");
          this.props.history.push("/signin");
        } else {
          this.getUser();
          // console.log(JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        sessionStorage.setItem("userData", JSON.stringify(response.data));
        let primaryGoal = response.data.primary_goal;
        let secondaryGoals = response.data.secondary_goals;
        this.setState({ primaryGoal });
        this.setState({ secondaryGoals });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getExams() {
    let user = this.state.userData;
    let goal = user.primary_goal;
    let secondary_goals = user.secondary_goals;
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/active-exams?catagory_id=" + goal,
    })
      .then((response) => {
        this.setState({ loading: false });
        let examsList = response.data.exams;
        secondary_goals.forEach((element1) => {
          axios({
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            url: "/api/active-exams?catagory_id=" + element1,
          })
            .then((response) => {
              if (response.data && response.data.exams) {
                response.data.exams.forEach((element) => {
                  examsList.push(element);
                });
              }
            })
            .catch((error) => {
              console.log(error.response.data.errors[0].message);
            });
        });
        this.setState({ examsList });
        // examsList.forEach((element) => {
        //   this.getInstructors(element.exam_creator_id);
        // });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  // getInstructors(instructorId) {
  //   let finalList = [];
  //   if (this.state.finalInstructors) {
  //     finalList = this.state.finalInstructors;
  //   }
  //   axios({
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     url: "/api/users/instructors/" + instructorId,
  //   })
  //     .then((response) => {
  //       let instructor = response.data;
  //       let newInstructorData = {
  //         first_name: instructor.first_name,
  //         last_name: instructor.last_name,
  //         profile_image: AppConstants.IMG_URL + instructor.profile_image,
  //         bio: instructor.bio,
  //         email: instructor.email,
  //         education: instructor.education,
  //         user_id: instructor.user_id,
  //       };
  //       finalList.push(newInstructorData);
  //       this.setState({ finalInstructors: finalList });
  //     })
  //     .catch((error) => {
  //       // console.log(error.message);
  //       console.log(error.response.data.errors[0].message);
  //     });
  // }

  getExam(exam_id) {
    this.props.history.push("/exampage?examId=" + exam_id);
  }

  viewInstructor(instructorId) {
    this.props.history.push("/instructor-page?instructorId=" + instructorId);
  }

  studentprofile() {
    this.props.history.push("/studentprofile");
  }

  enrolledexams() {
    this.props.history.push("/enrolled-exams");
  }

  studentSubscriptions() {
    this.props.history.push("/transactions");
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          <br></br>
          <div className="row">
            <Sidebar />
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 mob-pro ">
              <div className="">
                <div className="container">
                  <h6 className="mt-3">Goal Based Exams</h6>
                  <hr />
                  {this.state.examsList?.length === 0 ? (
                    <div className="row">
                      <div className="col">
                        We are working on exams based on your goals, you may
                        explore other exams below.
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      {!this.state.examsList
                        ? "Loading..."
                        : this.state.examsList
                            .filter((exam) => exam.exam_desc !== "blogpost") // Filter out exams where exam_desc is "blogposts"
                            .map((exam) => (
                              <div className="col-md-3 my-3" key={exam.exam_id}>
                                <a
                                  className="text-decoration-none"
                                  href={"/exampage?examId=" + exam.exam_id}
                                >
                                  <div
                                    className="card mt"
                                    href={"/exampage?examId=" + exam.exam_id}
                                  >
                                    <div className="card-image">
                                      <figure className="image">
                                        <img
                                          src={
                                            this.state.image + exam.exam_image
                                          }
                                          alt="category"
                                          height={
                                            AppConstants.POPULAR_CATEGORIES_HEIGHT
                                          }
                                          width={
                                            AppConstants.POPULAR_CATEGORIES_WIDTH
                                          }
                                        />
                                      </figure>
                                    </div>
                                    <div className="card-content text-center">
                                      <p className="text-dark cat-name text-center">
                                        {exam.exam_title}
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))}
                    </div>
                  )}
                </div>
                <Categories />
                <Cards />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
