import React, { Component } from "react";
import axios from "axios";
import { checkUser } from "../../utils/authHelper"; // Adjust the path accordingly
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AppConstants from "../../../Constants/AppConstants";
import "./CategoryPage.css";
import Sidebar from "../../Sidebar";
import { Fade } from "react-awesome-reveal";
import { Accordion, Card, Button } from "react-bootstrap"; // Import Bootstrap components

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      usersType: sessionStorage.getItem("userType"),
      isRegistered: sessionStorage.getItem("isInstructor"),
      instructorRedirect: false,
      instructorList: [],
      instructor: {},
      finalInstructors: [],
      final: [],
      examsList: [],
      loading: true,
      verified: false,
      image: AppConstants.IMG_URL,
      cat: {},
      loginStatus: false,
      blogData: {}, // Add state to store blog data
      activeKey: null,
    };
    this._isMounted = false; // Initialize _isMounted to false in the constructor
  }

  async componentDidMount() {
    this._isMounted = true; // Set the instance variable to true when the component mounts

    try {
      // Call the checkUser function from the utility and wait for completion
      await checkUser(this.props, this._isMounted);

      // After the user is checked, continue with the remaining logic
      if (this.state.userData) {
        let search = new URLSearchParams(this.props.location.search);
        if (search) {
          let catId = search.get("categoryId");
          if (catId) {
            await Promise.all([
              this.getCat(catId),
              this.getExams(catId),
              this.getBlogs(catId), // Fetch blogs as well
            ]);
          }
        }
      }

      // Update the login status if the component is still mounted
      if (this._isMounted) {
        this.setState({ loginStatus: !!this.state.userData });
      }
    } catch (error) {
      if (this._isMounted) {
        console.log(error.message);
      }
    }
  }

  componentWillUnmount() {
    // Set _isMounted to false to prevent updates after the component is unmounted
    this._isMounted = false;
  }

  async getCat(catId) {
    const source = axios.CancelToken.source();
    axios({
      method: "GET",
      url: "/api/catagories/" + catId,
      cancelToken: source.token,
    })
      .then((response) => {
        let cat = response.data;
        this.setState({ cat });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    this.cancelTokens.catId = source;
  }

  async getExams(catId) {
    const source = axios.CancelToken.source();
    axios({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: "/api/active-exams?catagory_id=" + catId,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({ loading: false });
        let examsList = response.data.exams;
        this.setState({ examsList });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    this.cancelTokens.catId = source;
  }

  enrollForBlog(blogId) {
    window.open(`/blog/${blogId}`, "_blank");
  }

  // New function to fetch blogs
  async getBlogs(catId) {
    try {
      // Use the catId dynamically from the URL parameter
      const response = await axios.get(
        `/api/blogs/v1?catagory=${catId}&status=active`
      );

      if (response.data) {
        const blogData = this.organizeBlogs(response.data.blogs);
        this.setState({ blogData });
      }
    } catch (error) {
      console.log("Error fetching blogs:", error.message);
    }
  }

  // Helper function to organize blogs into a domain-category structure
  organizeBlogs(blogs) {
    let blogData = {};
    blogs.forEach((blog) => {
      const domainName = blog.domain_name || "Other Domain";
      const categoryName = blog.catagory_name || "Other Category";

      if (!blogData[domainName]) {
        blogData[domainName] = {};
      }

      if (!blogData[domainName][categoryName]) {
        blogData[domainName][categoryName] = [];
      }

      blogData[domainName][categoryName].push(blog);
    });
    return blogData;
  }

  async getExam(exam_id) {
    this.props.history.push("/exampage?examId=" + exam_id);
  }

  getBlogPage(blog_id) {
    this.props.history.push(`/blog/${blog_id}`);
  }

  cancelTokens = { catId: null, examsCatId: null };

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }

    const { blogData } = this.state; // Destructure blogData from state

    return (
      <div className="container">
        <br />
        <div className="row">
          <Sidebar />
          <div className="container col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div className="repo-cards-div-main">
              {this.state.examsList.map((exam, i) => {
                return (
                  <div key={i} className="repo-card-div">
                    <Fade bottom duration={2000} distance="40px">
                      <div
                        key={i + "key"}
                        onClick={() => {
                          this.getExam(exam.exam_id);
                        }}
                      >
                        <div className="repo-name-div">
                          <object
                            data="/images/test-icon.svg"
                            width="25"
                            height="25"
                          ></object>
                          <p className="repo-name">{exam.exam_title}</p>
                        </div>
                        <p className="repo-description">{exam.exam_desc}</p>
                        <div className="repo-details">
                          <p className="repo-duration">
                            Access:{" "}
                            {exam.exam_mode === "public"
                              ? "Premium"
                              : exam.exam_mode === "private"
                              ? "Premium+"
                              : "Free"}
                          </p>

                          <p className="repo-duration">
                            Questions: {exam.number_of_questions}
                          </p>
                          <p className="repo-duration">
                            Time: {exam.exam_duration} Mins
                          </p>
                          <p className="repo-duration">
                            Marks: {exam.total_exam_score}
                          </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                );
              })}
            </div>

            <Fade bottom duration={5000} distance="40px">
              <Accordion defaultActiveKey="0">
                {blogData &&
                  Object.keys(blogData).map((domainName, domainIdx) => {
                    const domainBlogs = blogData[domainName] || {};
                    return Object.keys(domainBlogs).map(
                      (categoryName, categoryIdx) => {
                        const categoryBlogs = domainBlogs[categoryName].filter(
                          (blog) => blog.blog_status === "active"
                        );

                        if (categoryBlogs.length === 0) return null;

                        // Track if the current section is expanded or collapsed
                        const isExpanded =
                          this.state.activeKey ===
                          `${domainIdx}-${categoryIdx}`;

                        return (
                          <Card key={`${domainIdx}-${categoryIdx}`}>
                            <Card.Header
                              style={{
                                backgroundColor: "#212529",
                                padding: "8px 10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* + or - symbol to indicate expand/collapse */}
                              <span
                                style={{
                                  marginRight: "10px",
                                  fontSize: "1.5rem",
                                  color: "#007bff",
                                  cursor: "pointer",
                                }}
                              >
                                {isExpanded ? "−" : "+"}
                              </span>

                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={`${domainIdx}-${categoryIdx}`}
                                style={{
                                  fontSize: "1rem",
                                  color: "#007bff",
                                  textDecoration: "none",
                                  padding: "0",
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                onClick={() =>
                                  this.setState({
                                    activeKey: isExpanded
                                      ? null
                                      : `${domainIdx}-${categoryIdx}`,
                                  })
                                } // Toggle the active section
                              >
                                <p className="repo-name">{`${domainName} : ${categoryName}`}</p>
                              </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse
                              eventKey={`${domainIdx}-${categoryIdx}`}
                            >
                              <Card.Body>
                                {categoryBlogs.map((blog, blogIdx) => (
                                  <div key={blogIdx} className="blog-item">
                                    <div
                                      onClick={() =>
                                        this.enrollForBlog(blog.blog_id)
                                      } // Call enrollForBlog instead of getBlogPage
                                    >
                                      <div className="repo-name-div">
                                        <object
                                          data="/images/test-icon.svg"
                                          width="25"
                                          height="25"
                                        ></object>
                                        <p className="repo-name">
                                          <span
                                            style={{
                                              color: "darkblue",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {blog.blog_title}
                                          </span>{" "}
                                          | Access:{" "}
                                          {blog.blog_mode === "pplus" ? (
                                            <span>✨ Premium+</span>
                                          ) : (
                                            blog.blog_mode
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.blog_mode.slice(1)
                                          )}{" "}
                                          | Creation Date:{" "}
                                          {new Date(
                                            blog.creation_date
                                          ).toLocaleDateString("en-GB")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      }
                    );
                  })}
              </Accordion>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryPage;
