import React, { Component } from "react";
import { checkUser } from "../../utils/authHelper"; // Adjust the path accordingly
import "./Style.css";
import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Sidebar from "../../Sidebar";
import { withRouter } from "react-router";

class ExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examId: "",
      examDetails: {},
      examDomainDetails: {},
      examCategoryDetails: {},
      instructions: {},
      totalQuestions: 100,
      totalTime: 60,
      totalMarks: 100,
      loading: true,
      cat: "",
      domain: "",
      enrollments: [],
      enrolled: false,
      enrollment_id: "",
      isChecked: false,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  // handleCheckboxChange(event) {
  //   this.setState({ isChecked: event.target.unchecked });
  //   console.log("realtime: " + this.state.isChecked);
  // }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
    console.log("realtime: " + this.state.isChecked);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isComponentMounted = true; // Track component mount status
    this.checkUser();
  }

  componentWillUnmount() {
    this.isComponentMounted = false; // Track component unmount
  }

  checkUser() {
    checkUser(this.props, this.isComponentMounted)
      .then(() => {
        if (this.isComponentMounted) {
          let search = new URLSearchParams(this.props.location.search);
          let examId = search.get("examId");

          if (examId) {
            this.getExamById(examId);
            this.getEnrollments();
            this.setState({ examId });
          } else {
            console.log("Exam not found.");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        // Optional: handle the error here or leave it as it's already logged in checkUser
      });
  }

  getExamById(examId) {
    axios({
      method: "GET",
      headers: {
        ContentType: "application/json",
      },
      url: "/api/active-exams/" + examId,
    })
      .then((response) => {
        this.setState({ examDetails: response.data });
        this.setState({ examCategoryDetails: response.data.catagory_id });
        //this.getCat(this.state.examCategoryDetails);
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  // startExam = (enrollment_id) => {
  //   // this.props.onButtonClick(this.state.isChecked);
  //   this.props.history.push("/exam?enrollment=" + enrollment_id);
  // };

  handleButtonClick(enrollment_id) {
    //this.props.onButtonClick(this.state.isChecked);
    // redirect to api url

    //https://stackoverflow.com/questions/55526438/how-to-get-the-parameter-in-react-using-history-push
    this.props.history.push("/exam?enrollment=" + enrollment_id, {
      isPractice: this.state.isChecked,
    });
  }

  // async getCat(catagory_id) {
  //   const { data: response } = await axios
  //     .get("/api/catagories/" + catagory_id)
  //     .catch((error) => {
  //       console.log(error.response.data.errors[0].message);
  //     });
  //   this.setState({ cat: response.catagory });
  //   let domain = response.domain;
  //   this.setState({ domain: domain.domain });
  // }
  enrollForExam(examId) {
    let data = {
      exam_id: examId,
    };

    axios({
      method: "POST",
      headers: {
        ContentType: "application/json",
      },
      url: "/api/enrollments/",
      data: data,
    })
      .then(() => {
        console.log("Enrollment Successful");
        window.location.reload();
      })
      .catch(() => {
        const { exam_mode, exam_creator_id } = this.state.examDetails; // Extracting from examDetails
        this.props.history.push(
          `/subscription?examId=${examId}&mode=${exam_mode}&creator=${exam_creator_id}`
        );
      });
  }

  getEnrollments() {
    axios({
      method: "GET",
      url: "/api/students/enrollments",
    })
      .then((response) => {
        let enrollments = response.data.enrollments;
        this.setState({ enrollments });
        let examId = this.state.examId;
        enrollments.forEach((element) => {
          if (element.exam_id === examId) {
            this.setState({ enrolled: true });
            this.setState({ enrollment_id: element.enrollment_id });

            axios({
              method: "GET",
              headers: {
                ContentType: "application/json",
              },
              url:
                "/api/exams/enrollments/" +
                element.enrollment_id +
                "?instructions=yes",
            })
              .then((response) => {
                this.setState({ instructions: response.data.instructions });
              })
              .catch((error) => {
                console.error();
                console.log(error.response.data.errors[0].message);
              });
          }
        });
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="loader text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={3000}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="report--header">
            <h6 className="mt-3">{this.state.examDetails.exam_title}</h6>
          </div>
          <br />
          <div className="row">
            <Sidebar />
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 mob-pro  exam-instruction">
              <div className="row">
                <div className="col-10">
                  {this.state.enrolled ? (
                    <div className="modal-body">
                      <ol className="exam-li">
                        <li>
                          {" "}
                          Please note that this exam has a time limit. Count
                          down starts from the moment you start your attempt and
                          you must submit before the allocated time expires.
                        </li>
                        {this.state.instructions[0] ? (
                          <li> {this.state.instructions[0]}</li>
                        ) : (
                          <></>
                        )}
                        {this.state.instructions[1] ? (
                          <li>{this.state.instructions[1]}</li>
                        ) : (
                          <></>
                        )}
                        {this.state.instructions[2] ? (
                          <li>{this.state.instructions[2]}</li>
                        ) : (
                          <></>
                        )}
                        {this.state.instructions[3] ? (
                          <li>{this.state.instructions[3]}</li>
                        ) : (
                          <></>
                        )}
                        {this.state.instructions[4] ? (
                          <li>{this.state.instructions[4]}</li>
                        ) : (
                          <></>
                        )}
                        {this.state.instructions[5] ? (
                          <li>{this.state.instructions[5]}</li>
                        ) : (
                          <></>
                        )}
                      </ol>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <br />
              <div className="row reports-details">
                <div className="col-xs-4 col-sm-4 col-md-3 ">
                  <img
                    className="report-images"
                    src="images/aim.png"
                    alt="attempts"
                  />
                  <br />
                  <b>Total Score: </b>

                  {this.state.examDetails.total_exam_score}
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3 ">
                  <img
                    className="report-images"
                    src="images/bar.png"
                    alt="graph"
                  />
                  <br />
                  <b>Total Questions: </b>
                  {this.state.examDetails.number_of_questions}
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3 ">
                  <img
                    className="report-images"
                    src="images/checklist.png"
                    alt="checklist"
                  />
                  <br />
                  <b>Exam Type:</b>
                  {this.state.examDetails?.exam_type === "multiple_choice"
                    ? " MCQs"
                    : " Descriptive"}
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3 ">
                  <img
                    className="report-images"
                    src="images/watch.png"
                    alt="watch"
                  />
                  <br />
                  <b>Time: </b>
                  {this.state.examDetails.exam_duration} min
                </div>
              </div>

              <br />
              <div className="row text-center">
                <div className="col-md-12 col-xs-12 col-sm-12 ">
                  {this.state.enrolled ? (
                    <>
                      {this.state.examDetails.study_mode ? (
                        <label className="right-padding text-left">
                          <input
                            type="checkbox"
                            checked={this.state.isChecked}
                            onChange={this.handleCheckboxChange}
                          />{" "}
                          I want to start the exam in study mode{" "}
                        </label>
                      ) : (
                        ""
                      )}
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mb-2 text-right"
                        onClick={() => {
                          this.handleButtonClick(this.state.enrollment_id);
                        }}
                        data-dismiss="modal"
                      >
                        Start Exam
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.enrollForExam(this.state.examId);
                      }}
                    >
                      Enroll for Exam
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(ExamPage);
