import React from "react";
// import './Style.css';
import { Component } from "react";
// import { Button } from '../../Button';
import axios from "axios";
import Loader from "react-loader-spinner";
import Moment from "moment";
import $ from "jquery";
import Sidebar from "../../Sidebar";
import { saveAs } from "file-saver";

export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentHistory: [],
      loading: true,
      paymentDetails: {},
    };
  }

  componentDidMount() {
    this.getPaymentDetails();
  }

  getPaymentDetails() {
    axios({
      method: "Get",
      url: "api/subscriptions/users/v1/subs",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(JSON.stringify(response));
      let completedPayment = [];
      let paymentHistory = response.data.subscriptions;
      paymentHistory.forEach((element) => {
        if (element.order_status === "complete") {
          element.subs_date = Moment.utc(element.subs_date)
            .local()
            .format("DD-MM-YYYY HH:mm:ss");
          element.subs_validity = Moment.utc(element.subs_validity)
            .local()
            .format("DD-MM-YYYY HH:mm:ss");
          completedPayment.push(element);
        }
      });
      this.setState({ paymentHistory: completedPayment });
      this.setState({ loading: false });
    });
  }

  viewDetails(subs_id) {
    axios({
      method: "Get",
      url: "api/subscriptions/users/v1/subs?subscription_id=" + subs_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(JSON.stringify(response));
      this.setState({ paymentDetails: response.data });
      let paymentDetails = this.state.paymentDetails;
      paymentDetails.subscriptions.subs_date = Moment.utc(
        paymentDetails.subscriptions.subs_date
      )
        .local()
        .format("DD-MM-YYYY HH:mm:ss");
      paymentDetails.subscriptions.subs_validity = Moment.utc(
        paymentDetails.subscriptions.subs_validity
      )
        .local()
        .format("DD-MM-YYYY HH:mm:ss");
      this.setState({ paymentDetails: paymentDetails });
      if (paymentDetails.subscriptions?.exam_id) {
        this.getExamById(paymentDetails);
      }
      $("#exampleModal").modal("show");
    });
  }

  getExamById(paymentDetails) {
    axios({
      method: "GET",
      url: "/api/active-exams/" + paymentDetails.subscriptions.exam_id,
    }).then((response) => {
      let exam = response.data;
      paymentDetails.examDetails = {
        exam_title: exam.exam_title,
        exam_subtitle: exam.exam_subtitle,
        catagory_name: exam.catagory_name,
        domain_name: exam.domain_name,
      };
      this.setState({ paymentDetails: paymentDetails });
    });
  }

  downloadReceipt(path) {
    saveAs(window.location.origin + path, "subscriptionreceipt.pdf");
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }
    return (
      <div>
        <div className="container">
          {/* <div className="report--header small">
            <h3>Subscriptions History</h3>
          </div> */}
          <br></br>
          <div className="row">
            <Sidebar />
            <div className="container col table-report table-responsive">
              <table className="table table-hover text-center">
                <thead>
                  <tr className="table-active">
                    <th scope="col">Sr No.</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Subscription Date</th>
                    <th scope="col">Model Type</th>
                    <th scope="col">Price</th>
                    <th scope="col">Validity</th>
                    <th scope="col">Receipt</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.paymentHistory.map((item, i) => {
                    return (
                      // (item.order_status === 'complete') &&
                      <tr className="table-light" key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.order_status}</td>
                        <td>{item.subs_date}</td>
                        <td>{item.subs_model_type}</td>
                        <td>
                          {item.currency} {item.price}
                        </td>
                        <td>
                          {item.subs_model_type === "onetime"
                            ? "Lifetime"
                            : item.subs_validity}
                        </td>
                        <td>
                          {item.receipt_path && (
                            <p
                              className="resume-exam"
                              onClick={() =>
                                this.downloadReceipt(item.receipt_path)
                              }
                            >
                              Download
                            </p>
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              this.viewDetails(item.subscription_id)
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "fit-content" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Subscription Details Here
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body table-responsive">
                  <table className="table">
                    <thead></thead>
                    {this.state.paymentDetails.subscriptions ? (
                      <tbody>
                        {/* <tr>
                                                    <th scope="row"></th>
                                                    <td><b>Subs Model Code:</b></td>
                                                    <td>{this.state.paymentDetails.subs_model.subs_model_code}</td>
                                                    <td><b>Sub Model Title:</b></td>
                                                    <td>{this.state.paymentDetails.subs_model.subs_model_title}</td>
                                                </tr> */}
                        {/* <tr>
                                                <th scope="row"></th>
                                                <td><b>Subs Model Dec:</b></td>
                                                <td col-3>{this.state.paymentDetails.subs_model.subs_model_desc}</td>
                                                <td></td>
                                                <td></td>
                                            </tr> */}
                        {this.state.paymentDetails.examDetails &&
                          this.state.paymentDetails?.subscriptions
                            ?.subs_model_type === "onetime" && (
                            <tr>
                              <th scope="row"></th>
                              <td>
                                <b>Exam:</b>
                              </td>
                              <td>
                                {
                                  this.state.paymentDetails.examDetails
                                    .exam_title
                                }
                              </td>
                              <td>
                                <b>Subtitle:</b>
                              </td>
                              <td>
                                {
                                  this.state.paymentDetails.examDetails
                                    .exam_subtitle
                                }
                              </td>
                            </tr>
                          )}
                        {this.state.paymentDetails.examDetails && (
                          <tr>
                            <th scope="row"></th>
                            <td>
                              <b>Category:</b>
                            </td>
                            <td>
                              {
                                this.state.paymentDetails.examDetails
                                  .catagory_name
                              }
                            </td>
                            <td>
                              <b>Domain:</b>
                            </td>
                            <td>
                              {
                                this.state.paymentDetails.examDetails
                                  .domain_name
                              }
                            </td>
                          </tr>
                        )}
                        {/* <tr>
                                                    <th scope="row"></th>
                                                    <td><b>Is Discounted:</b></td>
                                                    <td>{this.state.paymentDetails.subscriptions.is_discounted ? 'Yes' : 'No'}</td>
                                                    <td><b>Tax Amount:</b></td>
                                                    <td>{this.state.paymentDetails.subscriptions.tax_amoumt}</td>
                                                </tr> */}
                        <tr>
                          <th scope="row"></th>
                          <td>
                            <b>Validity:</b>
                          </td>
                          <td>
                            {/* {this.state.paymentDetails.subscriptions.subs_validity} */}
                            {this.state.paymentDetails.subscriptions
                              .subs_model_type === "onetime"
                              ? "Lifetime"
                              : this.state.paymentDetails.subscriptions
                                  .subs_validity}
                          </td>
                          <td>
                            <b>Date:</b>
                          </td>
                          <td>
                            {this.state.paymentDetails.subscriptions.subs_date}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row"></th>
                          <td>
                            <b>Model Type:</b>
                          </td>
                          <td>
                            {
                              this.state.paymentDetails.subscriptions
                                .subs_model_type
                            }
                          </td>
                          <td>
                            <b>Base Price:</b>
                          </td>
                          <td>
                            {this.state.paymentDetails.subscriptions.base_price}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row"></th>
                          <td>
                            <b>Disc Price:</b>
                          </td>
                          <td>
                            {this.state.paymentDetails.subscriptions.currency}{" "}
                            {this.state.paymentDetails.subscriptions.dis_price}
                          </td>
                          <td>
                            <b>Price:</b>
                          </td>
                          <td>
                            {this.state.paymentDetails.subscriptions.price}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row"></th>
                          {/* <td><b>Coupon Code:</b></td>
                                                    <td>{this.state.paymentDetails.subscriptions.is_discounted ? this.state.paymentDetails.subscriptions.coupon_code : ''}</td> */}
                          <td>
                            <b>Pay ref Id:</b>
                          </td>
                          <td>
                            {
                              this.state.paymentDetails.subscriptions
                                .payment_ref_id
                            }
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody></tbody>
                    )}
                  </table>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
